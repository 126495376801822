import api from '@/services/api';
import { http } from '@/services/api';

export const getLesson = async id => {
  const result = await api.get(`/lessons/${id}`);
  return result.data;
};

export const createLesson = async payload => {
  const result = await api.post(`/lessons`, payload);
  return result.data;
};

export const updateLesson = async payload => {
  const result = await api.patch(`/lessons/${payload.id}`, payload);
  return result.data;
};

export const deleteLesson = async id => {
  const result = await api.delete(`/lessons/${id}`);
  return result.data;
};

//helpers
export const getQuestionTypes = async () => {
  const result = await api.get(`questiontypes`);
  return result.data;
};

export const getMediaTypes = async () => {
  const result = await api.get(`mediatypes`);
  return result.data;
};

export const uploadImage = async payload => {
  const fd = new FormData();
  fd.append('image', payload);
  const result = await http.request({
    method: 'post',
    url: '/images',
    data: fd,
    headers: { 'content-type': 'multipart/form-data' }
  });
  return result.data;
};
