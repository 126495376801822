<template>
  <section class="flex-column">
    <section>
      <el-row>
        <el-col
            :span="4"
            v-for="(image, index) in images"
            :key="image.fileName"
        >
          <el-card
              :class="index !== 0 ? 'ml-4 card' : 'card'"
              :body-style="{ padding: '0.25rem' }"
          >
            <div style="height: 200px;">
              <img :src="`${imageRootPath}/${image.fileName}`" class="image"/>
            </div>
            <div style="padding: 0.5rem;">
              <div class="bottom clearfix">
                <div class="flex-row space-between">
                  <el-select
                      v-model="image.orientation"
                      :placeholder="$t('orientation.label')"
                  >
                    <el-option
                        v-for="o in orientations"
                        :key="`ori_${o.value}`"
                        :label="$t(`orientation.${o.label}`)"
                        :value="o.value"
                    ></el-option>
                  </el-select>
                  <ui-button class="z100"
                      type="red"
                      size="small"
                      @click="onDelete(image, $event)"
                  >{{ $t ('actions.remove') }}
                  </ui-button>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-badge
              is-dot
              class="badge-100"
              :hidden="
              !requiredImage || validModel.lesson.media.image[selectedLanguage]
            "
          >
            <el-card class="ml-4 card" :body-style="{ padding: '0.25rem' }">
              <el-upload
                  action
                  :auto-upload="false"
                  :on-change="onChange"
                  :show-file-list="false"
              >
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-card>
          </el-badge>
        </el-col>
      </el-row>
    </section>
  </section>
</template>

<script>
import {uploadImage} from '../api';
import UiButton from '@/components/ui/UiButton';

export default {
  components: {UiButton},
  props: {
    images: {
      type: Array,
      required: false,
      default: () => []
    },
    validModel: {
      type: Object,
      required: true
    },
    selectedLanguage: {
      type: String,
      required: true
    },
    requiredImage: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      orientations: [
        {label: 'none', value: null},
        {label: 'center', value: 'center'}
      ],      
      imageRootPath: process.env.VUE_APP_AZURE_BLOB_URL
    };
  },

  methods: {
    async onChange(event) {
      if (event) {
        const valid = this.beforeImageUpload (event.raw);
        if (valid) {
          try {
            this.loading = true;
            var res = await uploadImage (event.raw);
            const message = {message: 'upload.success', type: 'success'};
            this.$message (message);
            this.handleSucessImageUpload (res, event);
            this.loading = false;
          } catch (err) {
            this.loading = false;
            const message = {message: 'upload.failed', type: 'error'};
            this.$message (message);
          }
        }
      }
    },

    onDelete(image) {
      this.$emit ('onDeleteImage', image);
      // this.media.images = this.media.images.filter(i => !i.id === image.id);
    },

    handleSucessImageUpload(image) {
      this.$emit ('onAddImage', image);
    },

    beforeImageUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPng = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPng) {
        this.$message.error ({message: 'Image must be JPG or PNG format!'});
      }
      if (!isLt2M) {
        this.$message ({message: 'Image cannot exceed 2MB!'});
      }
      return (isJPG || isPng) && isLt2M;
    }
  }
};
</script>

<style>
.avatar-uploader .el-upload2 {
  border: 2px dashed #4299e1 !important;
  border-width: 2px !important;
  border-radius: 6px !important;
  cursor: pointer !important;
  position: relative !important;
  overflow: hidden !important;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff !important;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.card {
  min-height: 281px;
}

.badge-100 {
  width: 100%;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.space-between {
  justify-content: space-between;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}

.clearfix:after {
  clear: both;
}

.z100 {
  z-index: 100;
}
</style>
