<template>
  <section>
    <div id="questionType" v-loading="loading">
      <ui-form-field :label="$t('models.questiontype')" rules="required">
        <el-badge is-dot :hidden="selected !== null">
          <el-select
            v-model="selected"
            value-key="id"
            @change="onQuestionTypeChange"
            :placeholder="$t('actions.select')"
          >
            <el-option
              v-for="item in questionTypes"
              :key="item.id"
              :label="`${$t('questiontype.' + item.handle)}`"
              :value="item"
            ></el-option>
          </el-select>
        </el-badge>
      </ui-form-field>
    </div>

    <div>
      <ui-form-field :label="$t('models.question')" rules="required">
        <el-badge
          is-dot
          class="badge-100"
          :hidden="validModel.lesson.question.text[selectedLanguage]"
        >
          <rich-text-field
            type="text"
            v-model="
              question.text.filter(t => t.lang === selectedLanguage)[0].value
            "
            @input="onQuestionTextInput(selectedLanguage)"
          />
        </el-badge>
      </ui-form-field>
    </div>

    <div v-if="selected && selected.handle === 'multi'">
      <alternatives
        :alternatives="question.alternatives"
        :validModel="validModel"
        :selectedLanguage="selectedLanguage"
        @onAdd="onAlternativeAdd"
        @onRemove="onAlternativeRemove"
        @onChangeCorrect="onAlternativeCorrectChange"
        @onChangeText="onAlternativeTextChange"
      />
    </div>
  </section>
</template>

<script>
import UiFormField from '@/components/ui/UiFormField';
import RichTextField from '@/components/form/RichTextField';
import Alternatives from './AlternativesComponent';
import { getQuestionTypes } from '../api';
export default {
  props: {
    question: {
      type: Object,
      required: true
    },
    questionType: {
      type: Object,
      required: false
    },
    validModel: {
      type: Object,
      required: true
    },
    selectedLanguage: {
      type: String,
      required: true
    }
  },

  components: {
    UiFormField,
    RichTextField,
    Alternatives
  },

  data: function() {
    return {
      questionTypes: [],
      selected: null,
      loading: false
    };
  },

  methods: {
    onQuestionTextInput(change) {
      this.$emit('onQuestionTextInput', change);
    },

    onQuestionTypeChange(selected) {
      this.$emit('onQuestionTypeChange', selected);
      this.question.questionType = selected;
    },

    onAlternativeAdd() {
      this.$emit('onAlternativeAdd');
    },

    onAlternativeRemove(alternative) {
      this.$emit('onAlternativeRemove', alternative);
    },

    onAlternativeCorrectChange(alternative) {
      this.$emit('onAlternativeCorrectChange', alternative);
    },

    onAlternativeTextChange(alternative) {
      this.$emit('onAlternativeTextChange', alternative);
    },

    async getQuestionTypes() {
      this.loading = true;
      try {
        this.questionTypes = await getQuestionTypes();
        this.selected =
          this.question && this.question.questionType
            ? this.question.questionType
            : null;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        const msg = {
          message: this.$t('errors.load', {
            msg: this.$tc('models.questiontype', 2)
          }),
          type: 'error'
        };
        this.$message(msg);
      }
    }
  },

  async created() {
    this.getQuestionTypes();
  }
};
</script>

<style></style>
