<template>
  <section>
    <ui-form-field :label="$tc('models.alternative', 2)">
      <el-badge
        is-dot
        :hidden="
          validModel.lesson.question.alternativeHead.any &&
            validModel.lesson.question.alternativeHead.oneCorrect
        "
      >
        <ui-button
          type="green"
          class="el-button el-button--small new-alternative-btn"
          @click.prevent="onAdd"
        >
          {{ $tc('actions.add_new', 2) }}
          {{ $tc('models.alternative') }}
        </ui-button>
      </el-badge>
    </ui-form-field>
    <div v-for="(alt, i) in alternatives" :key="`alt_${i}`">
      <el-badge
        is-dot
        class="badge-100"
        :hidden="
          validModel.lesson.question.alternatives.find(
            a =>
              a.id === alt.id &&
              a.prop === 'text' &&
              a.lang === selectedLanguage
          ) === undefined
        "
      >
        <el-input
          :placeholder="$tc('models.alternative', 1)"
          v-model="alt.text.filter(t => t.lang === selectedLanguage)[0].value"
          @change="onChangeText(alt, i)"
          class="mb-2"
        >
          <el-switch
            v-model="alt.isCorrect"
            active-color="#13ce66"
            :active-text="`${alt.isCorrect ? $t('correct') : $t('incorrect')}`"
            inactive-color="#ff4949"
            @change="onChangeCorrect(alt)"
            slot="prepend"
          ></el-switch>
          <el-button slot="append" @click.prevent="onRemove(alt)">{{
            $t('actions.remove')
          }}</el-button>
        </el-input>
      </el-badge>
    </div>
    <span
      v-if="!validModel.lesson.question.alternativeHead.oneCorrect"
      class="block text-sm mt-2"
      >{{ $t('validation.one_correct_answer') }}</span
    >
  </section>
</template>

<script>
import UiFormField from '@/components/ui/UiFormField';
import UiButton from '@/components/ui/UiButton';
export default {
  props: {
    alternatives: {
      type: Array,
      required: true
    },
    selectedLanguage: {
      type: String,
      required: true
    },
    validModel: {
      type: Object,
      required: true
    }
  },

  components: {
    UiButton,
    UiFormField
  },

  data: function() {
    return {
      loading: false
    };
  },

  methods: {
    onAdd() {
      this.$emit('onAdd');
    },
    onRemove(alternative) {
      this.$emit('onRemove', alternative);
    },
    onChangeCorrect(alternative) {
      this.$emit('onChangeCorrect', alternative);
    },
    onChangeText(alternative) {
      this.$emit('onChangeText', alternative);
    }
  },

  async created() {
    this.localAlternatives = this.alternatives;
  }
};
</script>

<style>
.new-alternative-btn > p {
  font-size: 0.875rem !important;
  padding: 0.5rem 1rem !important;
  margin: 0 !important;
}
</style>
