<template>
  <ui-form-field :label="$tc('models.mediatype')" rules="required">
    <el-badge is-dot :hidden="selected !== undefined">
      <el-select
        v-model="selected"
        value-key="id"
        :placeholder="$t('actions.select')"
        @change="onMediaTypeChange"
      >
        <el-option
          v-for="item in mediaTypes"
          :key="item.id"
          :label="`${$t('mediatype.' + item.name)}`"
          :value="item"
        ></el-option>
      </el-select>
    </el-badge>
  </ui-form-field>
</template>

<script>
import UiFormField from '@/components/ui/UiFormField';
import { getMediaTypes } from '../api';
export default {
  props: {
    selectedMediaType: {
      type: Object,
      default: undefined
    }
  },

  components: {
    UiFormField
  },

  data: function() {
    return {
      mediaTypes: [],
      selected: undefined,
      loading: false
    };
  },

  methods: {
    onMediaTypeChange(selected) {
      this.$emit('onMediaTypeChange', selected);
    },

    async loadMediaTypes() {
      this.loading = true;
      try {
        this.loading = false;
        this.mediaTypes = await getMediaTypes();
        if (this.selectedMediaType)
          this.selected = this.mediaTypes.find(
            mt => mt.id === this.selectedMediaType.id
          );
      } catch (err) {
        this.loading = false;
        const message = {
          message: this.$t('errors.load', {
            msg: this.$tc('models.mediatype', 2)
          }),
          type: 'error'
        };
        this.$message(message);
      }
    }
  },

  created() {
    this.loadMediaTypes();
  }
};
</script>

<style></style>
